/* Load Fonts
---------------------------------------------- */
@font-face {
  font-family: MightCouldPen;
  font-weight: 400;
  src: url("../fonts/MightCouldPen.otf") format("opentype");
}

@font-face {
  font-family: MightCouldPencil;
  font-weight: 400;
  src: url("../fonts/MightCouldPencil.otf") format("opentype");
}

/* FONT family
---------------------------------------------- */
/* BACKGROUND Colors 
---------------------------------------------- */
/* FONT Colors 
---------------------------------------------- */
/* Responsive - @include bp-400
---------------------------------------------- */
/* Aspect Ratio
------------------------------------------------------------- */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "MightCouldPencil";
  line-height: 1.2;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #12235c !important;
}

.container {
  width: 100%;
  display: block;
  position: relative;
  overflow-X: hidden !important;
  flex: 1;
}

.section-small {
  font-size: 12px;
}

h1 {
  font-family: 'MightCouldPen', serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: #12235c !important;
}

@media (min-width: 1024px) {
  h1 {
    font-family: "MightCouldPencil";
    font-size: 62px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 1px;
  }
}

h2 {
  margin-bottom: 40px;
  display: block;
  font-family: "MightCouldPencil";
  font-size: 42px;
  font-weight: 600;
  color: #12235c !important;
}

@media (min-width: 1024px) {
  h2 {
    margin-bottom: 80px;
  }
}

h3 {
  margin-bottom: 20px;
  display: block;
  font-family: "MightCouldPencil";
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #0d2a57;
}

p {
  font-family: "MightCouldPencil";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: left;
  color: #12235c !important;
}

@media (min-width: 1024px) {
  p {
    text-align: justify;
  }
}

strong {
  font-family: "MightCouldPen";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: justify;
  color: #12235c !important;
}

.desktop-navigation {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@media (min-width: 1024px) {
  .desktop-navigation {
    display: flex;
  }
}

.desktop-navigation .desktop-content {
  width: 100%;
  padding: 2rem;
  display: flex;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
}

.desktop-navigation .desktop-content .desktop-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desktop-navigation .desktop-content .desktop-menu .menu-section {
  display: flex;
  flex-shrink: 0;
  min-width: 200px;
}

.desktop-navigation .desktop-content .desktop-menu .menu-logo {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-navigation .desktop-content .desktop-menu .menu-logo svg {
  width: auto;
  height: 150px;
}

.desktop-navigation .menu-section ul li {
  list-style: none;
  position: relative;
}

.desktop-navigation .menu-section ul li .dropdown {
  left: 0;
  top: calc(100% + 18px);
  white-space: nowrap;
  border-radius: 10px;
  display: none;
  padding: 10px;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 11px 35px 3px rgba(98, 96, 96, 0.26);
  z-index: 9999;
  background-color: white;
}

.desktop-navigation .menu-section ul li .dropdown:before {
  position: absolute;
  top: -10px;
  left: 27px;
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent #fff transparent;
}

.desktop-navigation .menu-section ul li .dropdown a {
  font-family: "MightCouldPen";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  padding: 20px 30px;
  text-decoration: none;
  color: #12235c !important;
}

.desktop-navigation .menu-section ul li:hover .dropdown {
  display: flex;
}

nav.mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  opacity: 0;
  background: #fff;
  z-index: -1000;
}

@media (min-width: 1024px) {
  nav.mobile {
    display: none;
  }
}

nav.mobile.active {
  z-index: 9999;
  opacity: 1;
}

nav.mobile ul {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav.mobile ul li {
  display: block;
  text-align: center;
}

nav.mobile ul li a {
  font-family: "MightCouldPen";
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: #12235c;
  line-height: 12.5vh;
}

header.mobile {
  display: block;
  width: 100%;
  position: relative;
  z-index: 300;
}

header.mobile .mobile-logo {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 30px 30px;
  text-align: right;
}

header.mobile .mobile-logo svg {
  width: auto;
  height: 100px;
}

header.mobile a {
  font-family: "MightCouldPen";
  font-size: 20px;
  font-weight: 600;
  float: left;
  width: 50%;
  line-height: 140px;
  padding: 30px 0 0 30px;
  z-index: 9999;
  text-decoration: none;
  color: #12235c !important;
}

@media (min-width: 1024px) {
  header.mobile {
    display: none;
  }
}

#menu-button {
  font-family: "MightCouldPen";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.6;
  padding: 20px 30px;
  text-decoration: none !important;
  color: #12235c !important;
}

.section-hero {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column !important;
}

.section-hero .section-hero_strapline {
  position: relative;
  width: 100%;
  z-index: 200;
}

@media (min-width: 1024px) {
  .section-hero .section-hero_strapline {
    display: flex;
    flex-direction: column !important;
    height: 100%;
    text-align: center;
    margin-bottom: -200px;
    margin-top: 100px;
  }
}

.section-hero .section-hero_strapline h1,
.section-hero .section-hero_strapline p {
  text-align: center !important;
}

.section-hero .section-hero_strapline p {
  font-size: 28px !important;
}

.section-hero img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 200;
}

.section-hero img.desktop {
  display: none;
}

.section-hero img.mobile {
  display: flex;
}

@media (min-width: 1024px) {
  .section-hero img.desktop {
    display: flex;
  }
  .section-hero img.mobile {
    display: none;
  }
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  float: left;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .section {
    flex-direction: row;
  }
}

.section .section-half {
  position: relative;
  float: left;
  width: 100%;
  flex-direction: row;
}

@media (min-width: 1024px) {
  .section .section-half {
    flex: 1;
    width: 50%;
  }
}

.section .section-half.section-half__image {
  position: relative;
  width: 100%;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.section .section-half.section-half__image:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 100%;
}

.section .section-half.section-half__image > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 1024px) {
  .section .section-half.section-half__image {
    flex: 1;
    width: 50%;
    height: 750px;
  }
}

.section .section-half.section-half__text {
  background: #fff;
  width: 100%;
}

@media (min-width: 1024px) {
  .section .section-half.section-half__text {
    flex: 1;
    width: 50%;
  }
}

.section .section-half.section-half__text .section_half__text-content {
  width: calc(100% - 40px);
  position: relative;
  margin: 20px;
  text-align: center;
}

@media (min-width: 1024px) {
  .section .section-half.section-half__text .section_half__text-content {
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    width: calc(100% - 160px);
  }
}

.section-therapy .section-half__text {
  order: 2;
}

@media (min-width: 1024px) {
  .section-therapy .section-half__text {
    order: 1;
    flex-direction: row;
  }
}

.section-therapy .section-half__image {
  order: 1;
}

@media (min-width: 1024px) {
  .section-therapy .section-half__image {
    order: 2;
    flex-direction: row;
  }
}

.section-testimonials {
  width: 100%;
  height: 850px;
  background: #00B0E5;
  position: relative;
  float: left;
}

@media (min-width: 550px) {
  .section-testimonials {
    height: 750px;
  }
}

.section-testimonials h2 {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  color: #FFF;
}

@media (min-width: 1024px) {
  .section-testimonials h2 {
    top: 80px;
  }
}

.section-testimonials .section-testimonial__item {
  width: 100%;
  height: 100%;
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
}

.section-testimonials .section-testimonial__item div {
  position: relative;
  width: calc(100% - 100px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .section-testimonials .section-testimonial__item div {
    width: 50%;
  }
}

.section-testimonials .section-testimonial__item div p {
  color: #FFF;
}

.section-testimonials .flickity-prev-next-button.previous {
  left: 10px;
  transition: all .3s ease-in-out;
}

.section-testimonials .flickity-prev-next-button.next {
  right: 10px;
  transition: all .3s ease-in-out;
}

.section-testimonials .flickity-page-dots {
  bottom: 80px;
  transition: all .3s ease-in-out;
}

.section-testimonials .flickity-enabled {
  position: relative;
}

.section-testimonials .flickity-enabled:focus {
  outline: none;
}

.section-testimonials .flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.section-testimonials .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section-testimonials .flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-testimonials .flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.section-testimonials .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.section-testimonials .flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: transparent;
  transform: translateY(-50%);
}

.section-testimonials .flickity-prev-next-button:focus {
  outline: none;
}

.section-testimonials .flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.section-testimonials .flickity-prev-next-button .arrow {
  fill: #EEEEEE;
}

.section-testimonials .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  transition: all .3s ease-in-out;
}

.section-testimonials .flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.section-testimonials .flickity-page-dots .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 8px;
  background: #eee;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.section-testimonials .flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.section-contact {
  width: 100%;
  height: 750px;
}

.section-contact .center {
  margin: 0 auto;
  width: calc(100% - 40px);
  height: auto;
  display: block;
}

@media (min-width: 1024px) {
  .section-contact .center {
    width: 50%;
  }
}

.section-contact h2 {
  position: relative;
  color: #FFF;
  margin: 40px 0;
  text-align: center;
}

@media (min-width: 1024px) {
  .section-contact h2 {
    margin: 80px 0;
  }
}

.section-contact input[type=text] {
  font-family: "MightCouldPencil";
  font-size: 16px;
  width: 100%;
  float: left;
  border: 1px solid #DDD;
  padding: 10px;
  display: block;
  margin: 0 0 20px 0;
  border-radius: 2px;
}

@media (min-width: 1024px) {
  .section-contact input[type=text] {
    margin: 0 10px 20px 0;
    width: calc(100% - 10px);
  }
}

.section-contact input[type=email] {
  font-family: "MightCouldPencil";
  font-size: 16px;
  width: 100%;
  float: left;
  border: 1px solid #DDD;
  padding: 10px;
  display: block;
  margin: 0 0 20px 0;
  border-radius: 2px;
}

@media (min-width: 1024px) {
  .section-contact input[type=email] {
    margin: 0 0 20px 10px;
    width: calc(100% - 10px);
  }
}

.section-contact textarea {
  font-family: "MightCouldPencil";
  font-size: 16px;
  width: 100%;
  height: 250px;
  border: 1px solid #DDD;
  padding: 10px;
  display: block;
  margin-bottom: 20px;
  border-radius: 2px;
}

.section-contact input[type=submit] {
  font-family: "MightCouldPen";
  font-size: 16px;
  color: #fff;
  background: #00B0E5;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .section-contact input[type=submit] {
    width: 50%;
  }
}

.section-contact label {
  width: 100%;
  float: left;
  display: block;
  margin-bottom: 5px;
}

.section-contact .content {
  width: 100%;
  height: 100%;
}

.section-contact .content .form {
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
}

@media (min-width: 1024px) {
  .section-contact .content .form {
    width: 100%;
  }
}

.section-contact .content .form .form-field {
  width: 100%;
  display: block;
  float: left;
}

@media (min-width: 1024px) {
  .section-contact .content .form .form-field {
    width: 50%;
  }
  .section-contact .content .form .form-field:nth-child(2) label {
    padding-left: 10px;
  }
}

.section-contact .content .form .form-field.textarea, .section-contact .content .form .form-field.submit {
  width: 100%;
}

.section-accreditations .logos {
  position: relative;
  margin: 40px auto;
}

.section-accreditations .logos img {
  height: 60px;
  width: auto;
  margin: 10px;
  float: left;
}

@media (min-width: 1024px) {
  .section-accreditations .logos img {
    height: 100px;
    width: auto;
    margin: 0 40px;
    float: none;
  }
}

.section-gallery {
  width: 100%;
  height: 350px;
  background: #00B0E5;
  position: relative;
  float: left;
}

.section-gallery .section-gallery__image {
  width: 100%;
  height: 100%;
  float: left;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  position: relative;
}

.section-gallery .section-gallery__image:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 100%;
}

.section-gallery .section-gallery__image > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 1024px) {
  .section-gallery .section-gallery__image {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  .section-gallery .section-gallery__image {
    width: 33.333%;
  }
}

.section-gallery .flickity-prev-next-button.previous {
  left: 10px;
  transition: all .3s ease-in-out;
}

.section-gallery .flickity-prev-next-button.next {
  right: 10px;
  transition: all .3s ease-in-out;
}

.section-gallery .flickity-page-dots {
  bottom: 80px;
  transition: all .3s ease-in-out;
}

.section-gallery .flickity-enabled {
  position: relative;
}

.section-gallery .flickity-enabled:focus {
  outline: none;
}

.section-gallery .flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.section-gallery .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section-gallery .flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.section-gallery .flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.section-gallery .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.section-gallery .flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: transparent;
  transform: translateY(-50%);
}

.section-gallery .flickity-prev-next-button:focus {
  outline: none;
}

.section-gallery .flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.section-gallery .flickity-prev-next-button .arrow {
  fill: #EEEEEE;
}

.section-gallery .flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 20px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  transition: all .3s ease-in-out;
}

.section-gallery .flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.section-gallery .flickity-page-dots .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 8px;
  background: #eee;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.section-gallery .flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.links-page-container {
  width: 100%;
  height: auto;
}

.links-page-container .links-content {
  justify-content: space-between;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  background-color: #fff !important;
}

@media (min-width: 1024px) {
  .links-page-container .links-content {
    display: flex;
    flex-direction: row;
  }
}

.links-page-container .links-content h3 {
  font-size: 43px;
  margin-bottom: 20px !important;
  font-weight: 600;
}

.links-page-container .links-content a {
  text-decoration: none !important;
}
